@import '../../styles/variables.scss';
@import '../../styles/_mixins.scss';

.appContainer {
    margin: 0 auto;
    max-width: 1000px;
    padding: 50px 20px 10px 20px;
}

@media screen and (min-width: 960px) {
    .appContainer {
        margin: 0 0 0 100px;
        max-width: none;
        padding: 0;
    }
}

.csvToast {
    position: fixed;
    bottom: 50px;
    z-index: 9999;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
    width: 80%;
    left: 10%;
    padding: 2rem;
    &Header {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        padding-right: 2rem;
        font-size: 1.6rem;
        svg {
            flex-basis: 3rem;
            max-width: 3rem;
            width: 3rem;
            margin-right: 1rem;
        }
    }
    &Close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
    }
    &Btn {
        background: lighten($primary, 7%);
        color: #fff;
        text-transform: uppercase;
        padding: .5rem 1.4rem;
        border-radius: .5rem;
        display: inline-block;
        font-weight: bold;
        cursor: pointer;
    }
}

@include breakpoint-up(md) {
    .csvToast {
        width: 660px;
        left: calc(50% - 330px);
    }
}
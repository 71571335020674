@import '../../styles/variables';
@import '../../styles/mixins';

.tabs {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    &Centered {
        justify-content: center;
    }
}

@import '../../styles/mixins.scss';

.logReading {
    padding: 20px;
}

.logReadingHeader {
	margin-bottom: 10px;
	font-size: 16px;
}
.bookTitleHeader {
	color: #757474;
	font-size: 18px;
	margin-bottom: 20px;
}

.logReadingField {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 1rem;
}


.logReadingSubHeader {
    font-size: 16px;
}

.logReadingComment {
    width: 100%;
}
.sep {
	border-top: 1px solid #D8D8D8;
	margin-top: 40px;
	padding-top: 40px;
}
.logReadingProbWord {
    background: #80b814;
    border-radius: 1rem;
    color: #FFF;
	display: flex;
	margin: 0 0.5rem 0.5rem 0;
	align-items: center;
	font-size: 1.4rem;
	font-weight: 800;
	padding: .5rem 1rem;
	&Delete {
		appearance: none;
		padding: 0;
		border: none;
		margin-left: .8rem;
		width: 1.6rem;
		height: 1.6rem;
		line-height: 1.3;
		border-radius: 1rem;
		background: rgba(0,0,0,0.2);
		cursor: pointer;
		&:focus {
			outline: none;
		}
		&:hover {
			background: rgba(0,0,0,0.1);
		}
	}
}

.pageNum {
	width: 70px;
}

//switch 

input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.switchLabel {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 26px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.switchLabel:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 3px;
	width: 22px;
	height: 22px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}



input:checked + label:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
}

.switchLabel:active:after {
	width: 40px;
}

.styledInput {
	background: #FFF;
	border: 1px solid #C6CBB5;
	border-radius: 10px;
	color: #696868;
	padding: 1px 5px;
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: lighten(#696868, 40%);
		opacity: 1; /* Firefox */
	  }
	  
	  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: lighten(#696868, 40%);
	  }
	  
	  &::-ms-input-placeholder { /* Microsoft Edge */
		color: lighten(#696868, 40%);
	  }
}

.studentCounter {
	border-top: 1px solid #CCC;
	border-bottom: 1px solid #CCC;
	padding: 10px 0;
}
.studentCountItem {
	display: flex;
	align-items: center;
	padding: 5px 0;
}
.studentCounterSelect {
	position: relative;
    label {
        padding-left: 1.8rem;
    }
    input ~ label:before {
        border: 1px solid #CCC;
        border-radius: 6px;
    }
}
.studentCounterHeader {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.studentCounterChevron {
	margin-left: auto;
}
@include breakpoint-up(sm) {
	.logReadingInputs {
		display: flex;
	}
	.logReadingField {
		margin-bottom: 0;
	}
}

.justNow {
	cursor: pointer;
}
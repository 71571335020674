@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';


.filters {
    padding: .5rem 1rem;
    background: #E1E1E1;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 1rem;
    &List {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
            margin-bottom: .5rem;
        }
        p {
            display: flex;
            align-items: center;
            margin-right: 1.6rem;
            svg {
                margin-right: .5rem;
            }
        }
    }
}
@include breakpoint-up(sm) {
    .filters {
        border-radius: 0;
        padding: .6rem 3rem;
        margin-left: -3rem;
        margin-right: -3rem;
        margin-top: -2rem;
        width: calc(100% + 6rem);
        &List {
            li {
                margin-bottom: 0;
            }
        }
    }
}


.orderDataWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:2rem;
}   
.chartLegend {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    li {
        display: inline-block;
        span {
            width: 1rem;
            height: 1rem;
            display: inline-block;
            margin-right: .5rem;
            background: #C6CBB5;
            border-radius: 1rem;
        }
        &:last-child {
            margin-left: 1rem;
        }
    }
    &Current {
        background: #C33764!important;
    }
}
.sortTypeSelect {
    max-width: 20rem;
}

.mainContent {
    display: flex;
    flex-direction: column;
    &Data {
        flex: 1;
    }
    &Averages {
        padding: 1rem;
        border-radius: 1rem;
        border: 1px solid #ccc;
        order: -1;
        margin-bottom: 2rem;
    }
}
@include breakpoint-up(lg) {
    .mainContent {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
        &Data {
            flex: 1;
        }
        &Averages {
            flex-basis: 22rem; //30
            max-width: 22rem;
            order: 0;
            margin-left: 3rem;
            padding: 2rem;

        }
    }
}

@include breakpoint-up(xl) {
    .mainContent {
        &Averages {
            flex-basis: 30rem; //30
            max-width: 30rem;
        }
    }
}
.averageList {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100rem;
    li {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        flex-basis: 18rem;
        max-width: 18rem;
        font-weight: bold;
        flex-direction: row;
    }
    &No {
        flex-basis: 4.5rem;
        max-width: 4.5rem;
        padding: 1rem 0;
        flex-shrink: 0;
        margin-right: 1rem;
        background: #D8D8D8;
        border-radius: .5rem;
        text-align: center;
        font-size: 1.6rem;
    }

    &Extra {
        font-size: 1.2rem;
        font-weight: normal;
    }
}
@include breakpoint-up(lg) {
   .averageList {
        flex-direction: column;
        li {
            flex-basis: auto;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &Books {
            width: calc(100% + 4rem);
            max-width: none!important;
            margin: 1.5rem -2rem 0 -2rem;
            padding: 1.5rem 1rem 0 2rem;
            border-top: 1px solid #ccc;
        }
    }
}


@media print {
    .mainContent,
    .mainContentData,
    .mainContentAverages {
        display: block;
    }
    .mainContentAverages {
        break-inside: avoid;
    }
}
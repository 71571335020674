@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.classStudentsTiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -.5rem;
    margin-right: -.5rem;
    margin-top: 2.5rem;
}
.classStudentList {
    margin: 2rem 0;
    &Header {
        margin: 0 1rem;
        display: none;
    }
    &Data {
        margin-left: 35rem;
        display: flex;
        align-items: center;
        flex: 1;
        margin-right: 24px;
        div {
            margin-right: 1px;
            text-align: center;
            display: flex;
            flex-basis: 14rem;
            max-width: 14rem;
            flex: 1;
            justify-content: center;
        }
    }
}
@include breakpoint-up(md) {
    .classStudentList {
        &Header {
            display: block;
        }
    }
}

.classActions {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &Link {
        text-transform: uppercase;
        font-size: 1.2rem;
        padding: 0;
        border: none;
        margin-right: 1.2rem;
        font-weight: 500;
        color: #696868;
        cursor: pointer;
        letter-spacing: .5px;
        &Icon {
            display: flex;
            align-items: center;
        }
        &:disabled,
        &Disabled {
            opacity: .7;
            pointer-events: none;
        }
       > button {
            text-transform: uppercase;
        }
        svg {
            width: 1.6rem;
            color: $mid-green;
            margin-right: .5rem;
        }
        &Flag {
            svg {
                margin-right: 0;
            }
        }
    }

    &Button {
        background: #000;
        border-radius: 10rem;
        padding: .2rem 1.4rem;
        font-size: 1.2rem;
        min-height: 2.6rem!important;
        border: none;
        cursor: pointer;
        line-height: 1.1!important;
        color: #fff;
        svg {
            color: #fff;
            width: 1.6rem;
        }
        &[disabled] {
            opacity: .5;
            cursor: default;
        }
        &Range {
            ul {
                white-space: unset!important;
                min-width: 240px;
            }
        }
    }
    &FlagBtn {
        text-transform: uppercase;
        svg {
            fill: #fff;
            color: #fff;
        }
        &Disabled {
            opacity: .5;
            pointer-events: none;
        }
    }
    &Search {
        border-radius: 10rem!important;
        padding: .6rem 1rem!important;
        margin-bottom: 0!important;
        font-size: 1.2rem!important;
        flex: 0!important;
        &Split {
            border: none !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            margin-left: 20px;
            width: 75px !important;
        }
    }

    &Main {
        width: 100%;
        display: flex;
        align-items: center;
        flex: 1;
        margin-bottom: 1rem;
    }
    &Sub {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &ToggleLayout {
        display: flex;
        align-items: center;
        button {
            text-transform: uppercase;
            font-size: 1rem;
            display: flex;
            margin-left: 1rem;
            flex-direction: column;
            svg {
                margin-bottom: .5rem;
            }
        }
    }
    &SelectAll {
        display: flex;
        align-items: center;
        margin-right: 2rem;
        margin-left: 1rem;
    }
}

.gemButton {
    display: flex!important;
    align-items: center;
    margin: auto;
    img, svg {
        margin-right: .5rem;
    }
}

.stCheck {
    label {
        padding-left: 1.8rem;
    }
    input ~ label:before {
        border-color: darken($gray-2, 3%);
        border-radius: 6px;
    }
}
.betaBanner {
    background: #CCC;
    border-radius: 10px;
    padding: 5px 10px;
    color: #4a4a4a;
    display: flex;
    align-items: center;
    &:hover {
        background: #DDD;
        cursor: pointer;
    }
}
.searchBarFilter {
    background: #3a3a3a;
    border: 0;
    color: #FFF;
    padding: 0.75em 1em;
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    &Active {
        background: #FFF;
        color: #000;
    }
}
@include breakpoint-up(sm) {
    .classActions { 
        margin-left: -3rem;
        margin-right: -3rem;
        padding-left: 3rem;
        padding-right: 3rem;
        background: #C33764;
        flex-direction: row;
        &Search {
            width: auto!important;
            margin-left: 1rem;
            &Split {
                margin-left: 1rem;
                width: 100px !important;
            }
        }
        &Main {
            margin-bottom: 0;
        }
        &Form {
            display: flex;
            align-items: center;
            margin-left: auto;
        }
    }
}
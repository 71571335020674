@import './variables.scss';

%label {
    display: inline-block;
    vertical-align: middle;
    border-radius: .5rem;
    padding: .3rem 1.2rem;
    min-width: 4rem;
    text-align: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 1.4rem;
}


.label {
    @extend %label;
    &.label-primary {
        background: $primary;
        color: #fff;
    }
    &.label-muted {
        background: $gray-2;
        color: #fff;
    }

    &.label-dark {
        background: lighten($gray-3, 10%);
        color: #fff;
    }

}

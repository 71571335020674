.title, .u-muli {
    font-family: 'Muli', sans-serif;
    font-weight: 400;
}
.subtitle {
    color: #787878;
    font-size: 24rem;
    font-weight: bold;
    &--underlined {
        border-bottom: 1px solid #787878;
        padding-bottom: 10px; 
    }
}
.u-bold,
.bold {
    font-weight: bold;
}
.bigger-text {
    font-size: 1.8rem;
    line-height: 24px;
}
.smaller-text {
    font-size: 1.4rem;
}
.small {
    font-size: 1.2rem;
}
.uppercase {
    text-transform: uppercase;
}
.altTitle, .altDesc {
    color: #FFF;
}
.lead {
    font-size: 1.8rem;
}
.underline {
    text-decoration: underline;
}
.newlines {
    white-space: pre-wrap;
}
.light {
    font-weight: normal;
}

@include breakpoint-up(sm) {
    .altTitle, .altDesc {
        color: #4a4a4a;
    }
}

@each $color, $value in $theme-colors {
    .u-text-#{$color} {color: $value!important};
  }

@import '../../styles/variables.scss';

.tab {
    border-bottom: solid 2px #d8d8d8;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0 10px;
    color: lighten($body-color, 30%);

    &:hover {
        color: #787878;
    }

    a {
        color: lighten($body-color, 30%);
        display: block;
    }
}

.selected,
.selected a {
    border-bottom-color: $yellow-accent;
    color: $body-color;
}

.iconTab {
    align-items: center;
    border-bottom-width: 3px;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    padding-bottom: 5px;
    text-align: center;
}

.iconSelected {
    color: $yellow-accent;
}

.disabled {
    color: #979797;
    pointer-events: none;
}
.dashWrapper {
    max-width: 100rem;
}
.noAccess {
    display: grid;
    gap: 2rem;
    grid-template-columns: 600px 250px;
}
.accounts {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    &Stats {
        color: #fff;
        padding: 2rem 1rem;
        border-radius: 1rem;
        span {
            font-weight: bold;
            display: block;
            font-size: 2.5rem;
        }
    }
    &Green {
        background: linear-gradient(to right, #80B814, #52770C) no-repeat left top;
    }
    &Red {
        background: linear-gradient(to right, #FF512F, #DD2476) no-repeat left top;
    }

    &LightBlue {
        background: linear-gradient(to right, #36D1DC, #5B86E5) no-repeat left top;
    }

    &Blue {
        background: linear-gradient(to right, #6F7FFF, #1D2671) no-repeat left top;
    }
}
.row2Cols {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    width: 100%;
}

.bookList {
    &Item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 1rem 0;
        gap: 1rem;
        align-items: center;
        border-bottom: 1px solid #ccc;
    }
    &Author {
        font-size: 1.2rem;
    }
}
.viewOptionBtn {
    border-radius: 1rem;
    border: 1px solid #ccc;
    padding: .5rem 1.2rem;
    margin: 0 1rem;
}

.data {
    &Pies {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
        width: 100%;
    }
    &PieChart {
        text-align: center;
        padding: 0 1rem;
    }
    &PieHead {
        margin-bottom: 2rem;
    }
}

.compStats {
    &Header {
        display: flex;
        margin-left: 230px;
        font-weight: bold;
    }
    &No {
        flex-basis: 100px;
        flex-shrink: 0;
        margin: 0 0.5rem;
    }
    
    &Row {
        padding: 1.4rem 0;
        border-bottom: 1px solid #E1E1E1;
        display: flex;
       
    }
    &Label {
        flex-basis: 230px;
        flex-shrink: 0;

    }
}

.demoSets {
    background: #f3f3f3;
    margin-bottom: .5rem;
    padding: 1rem;
    display: flex;
    
}
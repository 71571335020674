@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';


.chartWrapper {
    display: flex;
}
.chartArea {
    flex-shrink: 0;
    flex-grow: 1;
}
.chartLabels {
    max-width: 20rem;
    flex-basis: 20rem;
    margin-right: 2px;
    flex-shrink: 0;
    &Item {
        display: flex;
        align-items: center;
        height: 32px;
        overflow: hidden;
        padding: 2px 5px;
        a {
            &:hover {
                color: $primary
            }
        }
        &Name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    &Flag {
        width: 2rem;
        margin-right: 3px;
        svg {
            top: 2px;
            position: relative;
        }
    }
    &Data {
        margin-left: auto;
        font-weight: bold;
    }
    > li:nth-of-type(odd) {
        background: #f2f2f2;
    }
}

@media print {
    .chartLabels {
        width: 150px;
        float: left;
        display: block;
        &Flag {
            display: none;
        }
    }
    .chartWrapper {
        display: block;
        overflow: hidden;
        margin-bottom: 2rem;
        svg {
            margin: 0;
            padding: 0;
            display: block;
            overflow: visible;  
            width: 100%;
            
        }
    }
    .chartArea {
        width: 350px;
        float: left;
        display: block;
    }
}

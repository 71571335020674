%card-base {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 1rem;
    flex: 1 0 0;
  }
  .card {
      @extend %card-base;
      box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
      &.card-hover {
          color: $gray-3;
          transition: all .2s ease-in;
          &:hover {
            border-color: rgba(0, 0, 0, 0.2);
            box-shadow: 0 0.3rem .5rem 0 rgba(0, 0, 0, 0.2);
          }
      }
      &.card-shadow {
        box-shadow: 0 0rem .8rem 0 rgba(0, 0, 0, 0.4);
      }
  
      .card-header {
          padding: 1.5rem;
          border-bottom: 1px solid lighten($gray-1, 4%);
      }
      .card-body {
          flex: 1 1 auto;
          padding: 1.5rem;
      }
  }
  
  @include breakpoint-up(md) {
    .card {
          .card-body,
          .card-header {
              padding: 2rem;
          }
      }
  }
  
  .card-mobile {
      background: #fff;
      padding: 2rem;
      border-radius: 1rem;
      box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
  }
  
  @include breakpoint-up(sm) {
      .card-mobile {
          background: none;
          padding: 0;
          box-shadow: none;
      }
  }
  
  @media print {
      .card, .card-mobile {
          box-shadow: none;
          padding: 0;
          margin: 0;
      }
  }
  
@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.panel {
    border-radius: 1rem;
    margin-bottom: 2rem;
    padding: 2rem;
    background: #FAFAFA;
    max-width: 80rem;
}

.stCheck {
    display: flex;
    margin-bottom: 10px;
    flex-basis: 50%;
    max-width: 50%;
    flex-shrink: 0;

    label {
        padding-left: 2rem;
    }

    input~label:before {
        border-color: darken($gray-2, 3%);
        border-radius: 6px;
        top: 0;
        transform: none;
    }

    input:checked~label:after {
        top: 5px;
    }
}

.filterList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem 0;
}
.content {
    max-width: 60rem;
}
.downloads {
    margin-bottom: 2rem;
    li {
        padding: 1rem 0;
        border-bottom: 1px solid #ccc;
    }

    &Header {
        font-size: 1.6rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        svg {
            width: 2.5rem;
            flex-shrink: 0;
        }
        button {
            color: $primary;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &Title {
        display: flex;
        align-items: center;

        svg {
            margin-right: .5rem;
        }
    }

}
.pickerWrapper { 
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 2px .5rem;
    flex-basis: 240px;
    max-width: 280px;
    display: flex;
    background: #fff;
    align-items: center;
}
.picker {
    input {
        width: 100px;
        border: none;

    }
}
$green: #34A308;
$red: #DD3811;

.container {
    max-width: 1000px;
}

.heroCounters {
    background: #FFFFFF;
    border: 1px solid #DBDADA;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.27);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: space-between;
}

.heroStat {
    font-size: 32px;
    font-weight: bold;
}

.heroPara {
    display: flex;
    align-items: center;
    gap: 10px;
}

.heroBtn {
    border: none;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
.dataTable {
    border-collapse: collapse; 
}
.dataGreen {
    color: $green;
}
.dataRed {
    color: $red;
}
.dataRow {
    border-bottom: 2px solid #F4F4F4;
}
.dataCell {
    text-align: center;
    padding: 20px 0;
}

.colHeader {
    cursor: pointer;
    font-size: 14px;
}

.dataName {
    font-size: 18px;
    font-weight: bold;
}

.dataNameInner {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 0;
    text-align: left;
    cursor: pointer;
}

.studentItem {
    border-bottom: 1px solid #E6DFDF;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.studentsHeader, .studentsHeaderItem {
    display: flex;
    justify-content: space-between;  
}
.studentsHeaderItem {
    font-weight: bold;
    margin-bottom: 10px;
    color: #787878;
}
.closeStudents {
    padding: 0;
    border: none;
    cursor: pointer;
}
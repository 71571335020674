@import '../../styles/variables.scss';
@import '../../styles/_mixins.scss';

.panel {
    background: #F9F9F9;
    border-radius: 15px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    padding: 20px;
}

.panelHeading {
    color: #696868;
    font-family: 'Muli', sans-serif;
    font-size: 26px;
    padding-left: 40px;
    position: relative;    
}

.panelHeadingIcon {
    left: 0;
    position: absolute;
    top: 0;
}

.bandLink {
    background: #FFF;
    border-radius: 15px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    font-family: 'Muli', sans-serif;
    margin-bottom: 10px;
    padding: 10px 30px 10px 15px;
    position: relative;
    &Title {
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }
}
.bandEditInfo {
    padding: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    background: lighten($gray-1, 12%);
    h4, p {
        margin-bottom: 1rem;
    }
}
.bandIndex {
    font-size: 1.4rem;
    font-weight: bold;
    margin-right: 10px;
    text-align: center;
}
@include breakpoint-up(sm) {
    .bandIndex {
        flex-basis: 3rem;
        font-size: 1.8rem;
    }
}
.bandRankHeader {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    button {
        margin-right: 1rem;
    }
    &Edit {
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }
}

.bandsWrapper {
    max-width: 50rem;
}

.bandEditCols {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    &Main {
        flex: 1;
        width: 100%;
    }

}

@include breakpoint-up(md) {
    .bandEditCols {
        display: flex;
        flex-direction: row;
        &Main {
            flex: 1;
            flex-basis: 50rem;
            max-width: 50rem;
        }
        &Side {
           flex-basis: 40rem;
           max-width: 40rem;
           order: 1;
           padding-left: 2rem;
        }
    }
}
.bandRankItem {
    background: #FFF;
    border-radius: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    touch-action: none;
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    align-items: center;
    display: flex;
    cursor: grab;
    touch-action: none;

}

.bandEdit {
    margin-left: auto;
    cursor: pointer;
    margin-right: 1.5rem;
    z-index: 1;
    position: relative;
    padding: 1rem;
    &:hover {
        color: $primary;
    }
}
.bandRankColor {
    border-radius: 5px;
    height: 30px;
    margin-right: 10px;
    width: 30px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2), inset 0 0 1px rgba(0, 0, 0, 0.2);
}
.bandDrag {
    cursor: grab;
    touch-action: none;
    display: flex;
    align-items: center;
}

.bandNameEdit {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    input {
        margin-right: 1rem;
    }
}

.colorSet {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}
.colorLabel {
    margin-right: .5rem;
}

.bandLinkDelete {
    color: red;
    cursor: pointer;
    position: absolute;
    right: 5px;
}

.deleteInner {
    padding: 20px;
}
@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.row {
    padding: 1rem;
    border-bottom: 1px solid #E1E1E1;
    display: flex;
    align-items: center;
    &Inner {
        position: relative;
        display: flex;
        flex: 1;
        flex-shrink: 0;
        flex-direction: column;
    }
    &Link {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
        position: absolute;
        display: block;
    }
    &Main {
        display: flex;
        align-items: center;
        flex-basis: 15rem;
        flex-shrink: 0;
        max-width: 35rem;
        flex: 1;
    }
    &Info {
        position: relative;
        width: 100%;
        max-width: calc(100% - 8rem);
    }
    &Data {
        display: flex;
        align-items: center;
        flex: 1;
        flex-wrap: wrap;
        margin-top: 1rem;
    }
    &StCheck {
        z-index: 1;
        position: relative;
    }
    &Boomer {
        width: 6rem;
        margin-left: -1rem;
        position: relative;
        margin-right: 1rem;
    }
    &Flag {
        position: absolute;
        bottom:0;
        right:0;
        width: 2rem;
        height: 2rem;
        background: $primary;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &Book {
        font-size: 1.4rem;
        color: #696868;
    }

    &BookTitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    } 
}

@include breakpoint-up(md) {
    .row {
        &Inner {
            flex-direction: row;
            align-items: center;
        }
        &Data {
            margin-top: 0;
        }
    }
}

.logDates {
    &Item {
        text-align: center;
        color: darken($gray-2, 10%);        
        flex-basis: 14rem;
        max-width: 14rem;
        display: flex;
        flex: 1;
        font-size: 1.2rem;
        margin-right: 1px;
        justify-content: center;
    }
    &Img {
        width: auto;
        height: 1.8rem;
        margin: auto;
        margin-right: 5px;
    }  
}

@include breakpoint-up(md) {
    .logDates {
        &Img {
            display: none;
        }
    }
}


.bandPill {
    border-radius: 3px;
    height: 16px;
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right:5px;
}
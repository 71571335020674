.reviews {
    display: grid;
    grid-template-columns: 430px 1fr;
    gap: 2rem;
    align-items: flex-start;
    &Panel {
       // min-height: calc(100vh - 160px);
       min-height: 400px;
        background: #F1F1F1;
        border-radius: 2rem;
        display: flex;
        flex-direction: column;
        max-width: 800px;
        //margin-bottom: -30px;
    }
    &Inner {
        padding: 2rem;
        //overflow: auto;
        // flex: 1 1 0;
        // flex-basis: 0;
    }
}

.reviewItem {
    margin-bottom: 1rem;
    background: #F1F1F1;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    cursor: pointer;
    &Star {
        border-radius: 1rem;
        background: #07BEDC;
        width: 4.6rem;
        height: 4.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.review {
    &Header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        margin-bottom: 4rem;
        > div {
            background: #DADADA;
            text-align: center;
            padding: 1.5rem;
            border-radius: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &Footer {
        background: #DADADA;
        padding: 2rem;
        display: grid;
        justify-content: space-evenly;
        grid-template-columns: 1fr 1fr 1fr;
                border-radius: 0 0 2rem 2rem;
        gap: 2rem;
        margin-top: auto;
    }
}
@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.book {
    background: #FFF;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    display: flex;
    cursor: pointer;
    margin-bottom: 20px;
    max-width: 700px;
    position: relative;
}
.bookDetails {
    align-items: center;
    display: flex;
    padding: 20px;
    flex: 1;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    &:hover {
        background: #f1f1f1;
    }
}
.bookIcons {
    align-items: center;
    padding: 20px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    margin-left: auto;
    min-width: 75px;
    position: relative;
    &:hover {
        background: #f1f1f1;
    }
    &::before {
        content: '';
        left: 0;
        width: 1px;
        top: 10px;
        bottom: 10px;
        background: #CCC;
        position: absolute;
    }
}
.bookIconAdd {
    margin-left: auto;
}
.bookTitle {
    font-size: 22px;
    margin-bottom: 5px;
}

.bookGroup {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: normal;
}

.bookMeta {display: flex; align-items: center;}

.bookCompletedFlag {
    margin-left: 10px;
    font-size: 14px;
}
.activityContainer {
    display: grid;
   // grid-template-columns: 3fr 1fr;
    grid-template-columns: 3fr 290px;
}
.activities {
    margin: 20px 0 20px 50px;
    border-left: 1px solid #E1E1E1;
    padding-left: 25px; 
}

.activityDate {
    position: absolute;
    left: -70px;
    line-height: 14px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    top: 0;
}

.activityDot {
    background: #FFF;
    position: absolute;
    border: 1px solid #E1E1E1;
    height: 26px;
    width: 26px;
    border-radius: 100px;
    left: -38px;
    top: 0;
}
.activityDateSub {
    font-weight: normal;
    font-size: 12px;
    color: #8c8b8b;
}

.activity {
    position: relative;
}

.activityInner, .classActivity {
    color: #696868;
    margin: 10px 0;
    display: flex;
    padding: 15px;
}

.activity:nth-of-type(even) .classActivity,
.activity:nth-of-type(even) .activityInner {
    background: #f2f2f2;
    border-radius: 1rem;
}

.classActivity {
    display: block;
}
.activityStudent {
    color: #000;
    margin-left: 68px;
    margin-bottom: -20px;
    position: relative;
    display: block;
    cursor: pointer;
    &:hover {
        color: lighten(#000, 45%)
    }
}

.classActivityInner {
    display: flex;

}
.activityIcon {
    padding: 0 12px 0 8px;
    display: flex;
    justify-content: center;
}

.iconBox {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.iconBoxLog {
    background: #0BBEDB;
}
.iconBoxCompleted {
    background: #41D793 url('../../../images/icons/book_completed.svg') no-repeat center center;
}
.iconBoxNewBook {
    background: #6919EE url('../../../images/icons/book_added.svg') no-repeat center center;
}
.iconBoxReview {
    background: #ED9E17;
}
.iconBoxNote {
    background: #EE19B2;
}
.iconBoxBand {
    height: 20px;
}

.activityMain {
    font-size: 14px;
    flex: 1;
    &Header,
    &HeaderLink {
        font-size: 18px;
        font-weight: normal;
    }
    &HeaderLink {
        cursor: pointer;
    }
    &Author {
        font-size: 14px;
    }
}
.activityMainClass {
    padding-top: 20px;
}



// .activityDetails{
//     flex: 1
// }
// .activityTitle {
//     font-size: 18px;
// }
// .activityMeta {
//     text-align: right;
//     width: 200px;
// }
// .activityLogs, .activityAuthor {
//     margin-top: 5px;
//     align-items:center;
//     display: flex;
//     font-size: 16px;
// }
// .activityWord {
//     background: #80b814 !important;
// }
// .activityAuthor {
//     font-size: 14px;
//     justify-content: flex-end;
//     &Edit {
//         cursor: pointer;
//         &:hover {
//             color: #CCC;
//         }
//     }
// }

.loadMoreActivity {
    margin-left: 70px;
}
.actSide {
    margin-left: 20px;
}
.actFilters {
    background: #FAFAFA;
    padding: 20px 40px 20px 20px;
    border-radius: 10px;
    margin: 20px 0 0 0;
}

.readingBandBlob {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin: 0 16px 0 15px;
}

// .groupLabel {
//     background: #0097AF;
//     border-radius: 10px;
//     color: #FFF;
//     display: inline-block;
//     font-size: 14px;
//     padding: 5px 15px;
//     background: #1D2671;
// }

// .bandLabel {
//     border-radius: 15px;
//     font-size: 1rem;
//     font-weight: bold;
//     padding: 2px .5rem;
//     display: flex;
//     align-items: center;
//     border: 1px solid lighten($gray-2, 1%);
// }

// .bandPill {
//     border-radius: 1rem;
//     height: .8rem;
//     width: .8rem;
//     margin-right: .5rem;
// }

// @include breakpoint-up(sm) {
//     .activity {
//         color: #4a4a4a;
//     }
//     .activityWord {
//         background: #3a3a3a !important;
//     }
// }

.stCheck {
    display: flex;
    margin-bottom: 10px;
    label {
        padding-left: 2rem;
    }
    input ~ label:before {
        border-color: darken($gray-2, 3%);
        border-radius: 6px;
        top: 0;
        transform: none;
    }
    input:checked ~ label:after {
        top: 5px;
    }
}



.likeButton,
.seeButton {
    cursor: pointer;
    border: none;
    padding: 0;
    width: 2rem;
    height: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background: #E1E1E1;
    vertical-align: middle;
    margin-right: 2px;
    svg {
        color: #fff;
        width: 12px;
    }
    &:disabled {
        cursor: default;
    }
}

.likeButton {
    &Active {
        background: $accent-2;
    }
}
.seeButton {
    cursor: default;
    &Active {
        background: $primary;
    }
}
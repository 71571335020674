@import '../../styles/variables.scss';
@import '../../styles/_mixins.scss';

.band {
    align-items: center;
    display: flex;
    font-family: 'Muli', sans-serif;
    margin-bottom: 10px;
}

.bandIndex {
    font-size: 1.4rem;
    font-weight: bold;
    margin-right: 10px;
    text-align: center;
}
@include breakpoint-up(sm) {
    .bandIndex {
        flex-basis: 3rem;
        font-size: 1.8rem;
    }
}

.bandInput {
    border: 1px solid #CCC;
    border-radius: 10px;
    margin-right: 10px;
    padding: 5px 10px;
    min-width: 0;
}

.bandDrag {
    padding-left: 1rem;
    cursor: grab;
    &:hover {
        color: $primary;
    }
}


.bandColor {
    border-radius: 5px;
    cursor: pointer;
    display: block;
    height: 30px;
    margin-right: 10px;
    width: 30px;
    box-shadow: inset 0 0 1px rgba(0,0,0,.2), inset 0 0 1px rgba(0,0,0,.2);
}

.bandColorPreview {
    margin: 20px auto;
}

.colorLabel {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
}

.colorSet {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.bandRemove {cursor: pointer;}

.modalBg {
    align-items: center;
    background: rgba(255,255,255,0.8);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 500;
}

.modalInner {
    background: #FFF;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(0,0,0,0.4);
    padding: 20px;
    text-align: center;
    width: 400px;
    max-height: calc(100vh - 40px);
    overflow: auto;
}

.modalInnerAssign {
    font-family: 'Muli', sans-serif; 
    padding: 0;
    text-align: left;
}

.assignHeading {
    border-bottom: 1px solid #CCC;
    font-weight: '400';
    padding: 10px;
}
.assignContent {
    padding: 20px;
}
.assignSelect {
    border: 1px solid #9FC335;
    border-radius: 15px;
    padding: 5px;
}

.prevBands {
    border-top: 1px solid #CCC;
    margin: 20px;
    padding-top: 20px;
}

.prevBandsHeader {
    color: #4a4a4a;
    font-weight: normal;
}


.prevBandDate {
    color: #adadad;
    display: inline-block;
    width: 100px;
}

.bandsWrapper {
    max-width: 50rem;
}
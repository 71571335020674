// muli font 

@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;800&display=swap');


//print
@media print {
  .hidden-print {
      display: none!important;
  }
}

//Spacing
$spacer: 10px !default;
$spacers: (
  0: $spacer * 0,
  1: $spacer * 0.5,
  2: $spacer * 1,
  3: $spacer * 2,
  4: $spacer * 3
)!default;

$space-properties: (
  margin: m,
  padding: p
);


@each $prop, $abbr in $space-properties {
  @each $size, $len in $spacers {
    .u-#{$abbr}-top-#{$size} {
      #{$prop}-top: $len !important;
    }
    .u-#{$abbr}-base-#{$size} {
      #{$prop}-bottom: $len !important;
    }
    .u-#{$abbr}-left-#{$size} {
        #{$prop}-left: $len !important;
    }
    .u-#{$abbr}-right-#{$size} {
        #{$prop}-right: $len !important;
    }
    .u-#{$abbr}-#{$size} {
        #{$prop}: $len !important;
    }
  }
}

.u-m-left-auto {
    margin-left: auto;
}
.u-m-right-auto {
    margin-left: auto;
}


.u-m-top-5 {
  margin-top: 5px;
}
.u-m-top-10 {
  margin-top: 10px;
}

.u-m-top-20 {
  margin-top: 20px;
}
.u-m-left-5 {
  margin-left: 5px;
}
.u-m-right-5 {
  margin-right: 5px;
}
.u-m-right-10 {
  margin-right: 10px;
}
.u-m-right-20 {
  margin-right: 20px;
}
.u-m-right-40 {
  margin-right: 40px;
}

.u-m-top-40 {
  margin-top: 40px;
}
.u-m-base-5 {
  margin-bottom: 5px;
}
.u-m-base-10 {
  margin-bottom: 10px;
}
.u-m-base-20 {
  margin-bottom: 20px;
}
.u-padding-20 {
  padding: 20px;
}

// text helpers
// color
.u-t-white {
	color: #fff;
}

.error {
  color: red;
  font-family: 'Muli', sans-serif;
  font-size: 12px;
}
.separator {
  border: none;
  background: #ccc;
  height: 1px;
  margin: 2rem 0;
}

// alignment
.u-text-center {
  text-align: center;
}
.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

// display

.posRel {
  position: relative;
}
.u-display-flex {
    display: flex;
}
.u-align-center {
    align-items: center;
}
.u-justify-center {
  justify-content: center;
}
.u-justify-between {
  justify-content: space-between;
}
.u-justify-end {
  justify-content: flex-end;
}
.u-flex-center {
  display: flex;
  align-items: center;
}
.u-full-width {
  width: 100%;
}

.icon-heading {
  padding-left: 32px;
  position: relative;
  margin: 10px 0 0 0;
  &__icon {
    position: absolute;
    left: 0;
    top: 7px;
    color: #fff;
  }
}






@include breakpoint-up(sm) {
  .icon-heading {
    border-bottom: 1px solid lighten(#ccc, 10%);
    margin: -20px -30px 20px -30px ;
    width: calc(100% + 60px);
    padding: 20px 30px;
    &__icon {
      display: none;
    }
  }

}

.preventScroll {
  overflow: hidden;
}


.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: center;
  &__content {
      color: #fff;
      &__title {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 2.2rem;
      }
  }
  &__back {
    cursor: pointer;
  }
  &__actions {
    &__more {
      color: #fff;
    }
  }
}

@include breakpoint-up(sm) {
  .page-header {
      min-height: 8rem;
      border-bottom: 1px solid lighten($gray-1, 10%);
      margin: -2rem -3rem 2rem -3rem ;
      width: calc(100% + 6rem);
      padding: 1rem 3rem;
      &__content {
          color: initial;
          &__title {
            font-size: 3.2rem;
          }
      }
      &__actions {
        &__more {
          color: initial;
        }
      }
  }
}

@media print {
  .page-header {
    display: none;
  }
}

.week-picker {
  .react-datepicker__week:hover {
    .react-datepicker__day {
      background: teal!important;
      color: #fff;
    }
  }
  .react-datepicker__day--selected {
    background: orange!important;
  }
}

.week-calendar-wrapper .DayPicker-Month {
  border-collapse: separate;
}
.week-calendar-wrapper .DayPicker-WeekNumber {
  outline: none;
}
.week-calendar-wrapper .DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
}
.week-calendar-wrapper .DayPicker-Day--hoverRange {
  background-color: #EFEFEF !important;
}
.week-calendar-wrapper .DayPicker-Day--selectedRange {
  background-color: #B2DEFF !important;
  border-top-color: #8FCFFF;
  border-bottom-color: #8FCFFF;
  border-left-color: #B2DEFF;
  border-right-color: #B2DEFF;
}

.week-calendar-wrapper .DayPicker-Day--selectedRangeStart {
  background-color: #8FCFFF !important;
  border-left: 1px solid #8FCFFF;
}

.week-calendar-wrapper .DayPicker-Day--selectedRangeEnd {
  background-color: #8FCFFF !important;
  border-right: 1px solid #8FCFFF;
}

.week-calendar-wrapper .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.week-calendar-wrapper .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
  border-radius: 0 !important;
  color: black !important;
}
.week-calendar-wrapper .DayPicker-Day--hoverRange:hover {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
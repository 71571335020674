html { 
    font-size: 62.5%; /*for using REM units*/
}

body {
    font-family: 'Muli', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.3;
    color: #4A4A4A;
}

a {
    color: inherit;
    text-decoration: none;
}


.bar {
    height: 30px;
}
// .barLabel {
//     rect {
//         fill: #fff;
//     }
//     svg {
//         overflow: hidden;
//     }
// }
// .bar:nth-child(even) {
//     .barLabel rect {
//         fill: #f2f2f2;
//     }
// }

.ww {
    transform: translate(-25%, 0) scale(1);
}
.test {
    width: 30px!important;
    height: 30px!important;
}


@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.notes {
    max-width: 80rem;
}

.note {
    border-bottom: 1px solid #E1E1E1;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;

    &Side {

        font-size: 12px;
        color: #696868;
        padding-top: 2rem;
        order: 1;
    }

    &Main {
        flex: 1;
    }

    &Header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &Title {
            flex: 0 0 calc(100% - 4rem);
            max-width: calc(100% - 4rem);
            min-width: 1px;
        }

        &Edit {
            margin-left: auto;
        }
    }
}

@include breakpoint-up(md) {

    .note {
        flex-direction: row;
        &Side {
            flex: 0 0 160px;
            max-width: 160px;
            padding-right: 2rem;
            border-right: 1px solid #E1E1E1;
            text-align: right;
            padding-top: 0;
            order: 0;
        }

        &Main {
            padding-left: 2rem;
        }
    }
}
@import '../../styles/variables';
@import '../../styles/mixins';

.header {
    align-items: center;
    display: flex;
    height: 70px;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
    position: absolute;
    top: 0;
    &Logo {
        width: 5rem;
        &hover {
            opacity: 0.8;
        }
    }
    &Logout {
        border: none;
        color: #FFFFFF;
        cursor: pointer;
        padding: 0;
        &:hover {
            color: #D4D4D4;
        }
        &Icon {
            height: 32px;
            width: 32px;
        }
    }
}

@include breakpoint-up(sm) {
    .header {
        display: none;
    }
}


@media print {
    .header {
        display: none;
    }
}
// Breakpoints
$mobile-landscape: 480px;	// Mobile-landscape (and larger)
$tablet-portrait: 768px;	// Tablet-portrait (and larger)
$tablet-landscape: 992px;	// Tablet-landscape (and larger)
$laptop: 1200px;	// Laptops (and langer)

// Colors
$body-color: #4A4A4A;
$dark-green: #6B9A11;
$mid-green: #80B814;
$yellow-accent: #FFD653;
$accent-2: #C33764;

$primary: #6B9A11;
$tertiary: #0097AF;
//$dark-purple: #


$gray-1:     #D9D9D9 !default;
$gray-2:     #c1c1c1 !default;
$gray-3:     #4A4A4A !default;

$theme-colors: (
  "white"         : #fff,
  "primary"       : $dark-green,
  "secondary"      : $yellow-accent,
  "tertiary"      : $tertiary,
  'muted'         :$gray-2,
  'muted-2'       :darken($gray-2, 15%),
  'accent-2'      : $accent-2
);
@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';


.analyticsWrapper {
    max-width: 100rem;
}
.downLoadBtn {
    font-weight: bold;
    display: flex;
    align-items: center;
    svg {
        margin-right: .5rem;
    }
}
.filterHeader {
    background: #E2E2E2;
    padding: 2rem 3rem;
    margin-left: -2rem;
    margin-right: -2rem;
    margin-top: 0rem;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &Saved {
        padding: .5rem 3rem;
    }
    &Type {
        font-weight: bold;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        svg {
            margin-right: .5rem;
            width: 1.6rem;
        }
    }
    &Reads {
        margin-left: 2rem;
        &Input {
            max-width: 7rem;
            padding: .5rem 1rem!important;
        }
    }
}

@include breakpoint-up(sm) {
    .filterHeader {
        margin-left: -3rem;
        margin-right: -3rem;
        margin-top: -2rem;
    }
}

.inputCalendarBtn {
    padding: 0;
    border: none;
    cursor: pointer;
}
.subFilters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
   &Dates {
       display: flex;
       align-items: center;
       cursor: pointer;
       margin-bottom: 1rem;
   }
    &Opt {
        padding-right: 3rem!important;
        width: auto!important;
    }
    &WeekNav {
        padding: 0 3px;
        border: none;
        cursor: pointer;
        line-height: 1;
        &:hover {
            color: $primary;
        }
        &:disabled {
            opacity: 0.3;
            cursor: default;
        } 
    }
}

@include breakpoint-up(md) {
    .subFilters {
        flex-direction: row!important;
        &Dates {
            margin-bottom: 0;
        }
    }
}

.noData {
    border-radius: 1rem;
    padding: 5rem;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    margin: 3rem auto;
    max-width: 50rem;
}


.classItemsWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    flex: 0 1 auto;
    flex-direction: row;
    margin-right: -1rem;
    margin-left: -1rem;

}

.clsPanel {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 2rem 0;
}

@include breakpoint-up(md) {
    .clsPanel {
        flex: 0 0 calc(50% - 2rem);
        max-width: calc(50% - 2rem);
        margin: 0 1rem 2rem 1rem;
    }
}

.clsStats {
    display: flex;
    &Perc {
        flex-basis: 12rem;
        max-width: 12rem;
        margin-right: 1.5rem;
        padding-right: 1rem;
        border-right: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span {
            font-size: 1.6rem;
            top: 0.4rem;
            position: relative;
            padding-left: .3rem;
        }
        &Data {
            display: flex;
        }
        &Now {
            font-size: 3rem;
            margin-bottom: 1rem;
            margin-right: 2rem;
        }
        &Prev {
            font-size: 1rem;
            margin-left: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            svg {
                color: #C33764;
                width: 2.4rem;
            }
        }
    }
    &Main {
        color: #696868;
        flex: 1;
        h3 {
            color: #80B810;
        }
    }
    &List {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: row;
        margin-top: 2rem;
        li {
            display: flex;
            flex-basis: 33%;
            max-width: 33%;
            flex: 1;
            flex-direction: column;
            align-items: center;
            font-size: 1.2rem;
        }
        &No {
            font-weight: bold;
            font-size: 1.4rem;
            margin-bottom: .5rem;
        }
    }
}


@media print {
    .classItemsWrapper {
        display: block;
    }
    .clsPanel {
        float: left;
        width: 48%;
        border: 1px solid #ccc;
        margin-right: 1%;
        page-break-inside: avoid;
    }
    .subFilters {
        overflow: hidden;
        display: block!important;
        position: static;
        &Dates {
            float: left;
            margin-right: 1rem;
            position: relative;
            top: 6px;
        }
    }
    .subFiltersSelectors {
        float: left;
    }
}
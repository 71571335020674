.auth-page {
    &__container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 44rem;
        margin: auto;
        &:before {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: '';
            background: linear-gradient(to bottom, #c33764 0%, #1d2671 calc(100% - 11rem)) no-repeat left top;
            background-size: cover;
            z-index: -1;
        }
    }
}
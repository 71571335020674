.studentsPrint {
    background: #FFFFFF;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    overflow-y: auto;
}

.noPrint {
    background: #f2f2f2;
    margin-bottom: 2rem;
    padding: 1rem;
    &Content {
        max-width: 80rem;
        margin: 0 auto;
    }
}

.student {
    border-bottom: 1px solid #F2F2F2;
    padding: 30px 0;
    max-width: 80rem;
    margin: 30px auto;
    font-size: 1.6rem;
    &AppLogo {
        max-width: 8rem;
        height: auto;
        margin-bottom: 3rem;
        margin-left: -.5rem;
    }
    &Name,
    &School {
        font-weight: bold;
        font-size: 3.2rem;
        margin-bottom: 2rem;
    }
    &School {
        font-size: 2.4rem;
        margin-bottom: 0;
    }
    &Url {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2rem;
        &Body {
            flex: 1;
            margin-top: 1.5rem;
            font-size: 2.8rem;
        }
    }
    &QrImg {
        max-width: 10rem;
        height: auto;
        margin-right: 2rem;
    }
    &Code {
        font-size: 2.8rem;
        font-weight: bold;
    }

}


.letter {
    p {
        margin: 1rem 0;
    }
    &Title {
        font-size: 2.4rem;
    }
    &Url {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &QrImg {
            max-width: 10rem;
            height: auto;
            margin-left: 2rem;
        }
    }
    &Light {
        font-weight: normal;
    }
    &Code {
        font-size: 2rem;
        margin: 0!important;
        font-weight: bold;
    }
    &AppLinks {
        display: flex;
        align-items: center;
        margin: 1rem 0 1.5rem 0;
        img {
            margin-right: 1rem;
            height: 4rem;
            width: auto;
        }
    }
}

@media print {
    @page {
        size: letter portrait;
        margin: 1cm;
    }
    body {
        background: #fff;
    }

    .studentsPrint {
        position: static;
        margin-top: 6rem;
    }
    .studentsPrint + * {
        display: none;
    }
    .student {
        border-bottom: none;
        padding: 0;
        margin: 0 auto;
        max-width: 50rem;
    }
    .printSheet {
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        page-break-after: always;
        break-after: page;
    }
  }
@import '../../styles/variables';
@import '../../styles/mixins';


.sideModalOverlay {
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: rgba(0,0,0,.2);
}

.sideModal {
    background: #fff;
    box-shadow: 0px 0px 10px 10px rgba(0,0,0,.5);
    font-family: 'Muli', sans-serif;
    height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
    max-width: 700px;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    top: 20px;
    left: 20px;
    width: calc(100vw - 20px);
    border-top-left-radius: 15px;

    z-index: 101;
    animation: openModal .3s cubic-bezier(0, .52, 0, 1);
}


@keyframes openModal {
    0% {
        transform: translate3d(100vw, 0, 0);
    }

    100% {
        transform: translate3d(0vw, 0, 0);
    }
}


@include breakpoint-up(sm) {
    .sideModal {
        left: initial;
        right: 0;
    }

}


.modalCloseButton {
    padding: 0;
    border: none;
    right: 15px;
    position: absolute;
    cursor: pointer;
    top: 15px;
    z-index: 4;
}
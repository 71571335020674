body {
    position: relative;
}

.app {
    &__layout {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        position: relative;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: '';
            background: linear-gradient(to bottom, #c33764 0%, #1d2671 calc(100% - 11rem)) no-repeat left top;
            background-size: cover;
            z-index: -1;
        }
    }
    &__content {
        padding: 7rem 2rem 13rem 2rem;
    }
}

.impersonating {
    background: blue;
    color: white;
    font-size: 1rem;
    left: 0;
    opacity: 0.5;
    padding: .5rem;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 2000;
}

@include breakpoint-up(sm) { 
    .app {
        &__layout {
            background: #fff;
        }
        &__content {
            padding: 2rem 3rem 2rem 13rem;
            &--noSidePadding {
                padding: 0 0 0 10rem;
                min-height: 100vh;
            }
        }
    }
}


@media print {
    .app__layout {
        display: block;
        min-height: none;
        overflow: hidden;
        &:before {
            display: none;
            position: static;
        }
    }
    .app__content {
        padding-top: 0;
    }
}

[data-reach-dialog-overlay] {
    z-index: 900;
}
[data-reach-dialog-content] {
    border-radius: 15px;
    max-width: 500px;
    width: calc(100% - 40px);
}
@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.student {
    display: flex;
    margin-bottom: 3rem;
    flex-basis: 50%;
    padding-left: .5rem;
    padding-right: .5rem;
    max-width: 50%;
    flex-shrink: 0;
    position: relative;
    flex-direction: column;
    &Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
    }
    &Inner {
        border-radius: 1.5rem;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        padding: .6rem .6rem 0 .6rem;
        text-align: center;
        transition: all ease-in .1s;
        flex: 1;
        background: #fff;
        display: flex;
        flex-direction: column;
    }
    &Head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1rem;
        &Main {
            flex: 1;
            align-items: center;
            position: relative;
            &Flag {
                position: absolute;
                right: 5rem;
                top: .8rem;
            }
        }
        &Ava {
            border-radius: 300px;
            height: auto;
            width: 4rem;
            position: relative;
            margin-top: -1.6rem;
        }
        &GemButton {
            text-transform: none;
            text-align: left;
            justify-content: flex-start!important;
            font-weight: normal;
            min-width: 0;
            font-size: 1.6rem;
            img {
                display: none;
            }
            &:hover {
                background: none;
            }
        }

    }

    &Info {
        position: relative;
        margin-bottom: auto;
    }
    &GemAwarded {
        position: absolute;
        background: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    &Book {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1.4rem;
        color: darken($gray-2, 15%);
    }
    &BookTitle {
       // flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.stCheck {
    label {
        padding-left: 1.8rem;
    }
    input ~ label:before {
        border-color: darken($gray-2, 3%);
        border-radius: 6px;
    }
}
.stCheckTop {
        z-index: 1;
}

.logDates {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 1.2rem;
    position: relative;
    z-index: 2;
    margin-bottom: 1.5rem;
    &Item {
        flex: 1;
        color: darken($gray-2, 10%);
        cursor: default;
        padding: 0 2px;
    }
    &ImgHolder {
        height: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3px;
    }
    &Img {
        width: auto;
        height: 1.8rem;
        margin: auto;
    }
    &Ava {
        width: 1.8rem;
        height: auto;
        border-radius: 10rem;
    }
}

.bandLabel {
    background: #fff;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: bold;
    padding: 1px .5rem;
    display: inline-flex;
    align-items: center;
    border: 1px solid lighten($gray-2, 1%);
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);
}

.bandPill {
    border-radius: 1rem;
    height: .8rem;
    width: .8rem;
    margin-right: .5rem;
}

@include breakpoint-up(md) { 
    .student {
        flex-basis: 33%;
        max-width: 33%;
    }
}


@include breakpoint-up(lg) { 
    .student {
        flex-basis: 25%;
        max-width: 25%;
    }
}


.boomerImg {
    width: 80px;
    height: auto;
    margin: 0 auto;
}
@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.classLink {
    color: #80b814;
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    margin-top: 20px;
    text-decoration: underline;  
}
.bookContainer {
    background: #f1f1f1;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
}
.bookHeader {
    align-items: center;
    display: flex;
    margin-top: 20px;
}
.bookSubHeading {
    cursor: pointer;
    font-size: 18px;
    font-weight: normal;
    line-height: 18px;
    padding-left: 24px;
    position: relative;
}
.editHeader,.editMeta {
    display: flex;
    color: #716F6F;
}
.editPanel {
    background: #F3F3F3;
    border-radius: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);
    padding: 15px;
}
.noEntry {
    font-style: italic;
    font-size: 14px;
}
.editMeta, .readingBookSearch {
    align-items: center;
}
.editDate {
    margin-left: auto;
}
.bookSubHeadingIcon {
    position: absolute;
    left: 0;
    top: -4px;
}
.bookHeaderCover {
    height: auto;
    margin-right: 10px;
    width: 60px;
}

.probWord {
    background: #4a4a4a;
    border-radius: 10px;
    color: #FFF;
    display: block;
    margin-right: 10px;
    padding: 5px 10px;
}

.probWordList, .log, .actions, .readingBookSearch  {
    display: flex;
}

.bookFilters {
    background: #FFF;
    border-radius: 15px;
    display: flex;
    font-family: 'Muli', sans-serif;
    width: 100%;
}
.bookFiltersLog {
    background: none;
    display: block;
}
.filter {
    border: none;
    flex: 1;
}

.filterInner {
    border-bottom: 2px solid #E1E1E1;
    color: #c1c1c1;
    font-size: 16px;
    font-weight: 800;
    &Active {
        border-bottom: 2px solid #FFD653;
        color: #4a4a4a;
    }
}

.log {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}
.logMeta{
    flex: 1;
}


.listBook {
    background: #FFF;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    font-family: 'Muli', sans-serif;
    margin-bottom: 10px;
    padding: 10px 10px 10px 10px;
    position: relative;
    box-shadow: 0 1px 5px rgba(0,0,0,0.3);
}
.listBookMeta {
    flex: 1;
}

.listBookTitle {
    font-size: 12px;
    margin-bottom: 5px;
}

.listBookCover {
    height: auto;
    margin-left: 10px;
    width: 75px;
}

.listBookDate {
    align-items: center;
    bottom: 10px;
    color: #c1c1c1;
    display: flex;
    position: absolute;
}
.noProbWords {
    color: #FFF;
}
.bookBG  {
    background: rgba(0, 0, 0, 0.2);
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
}
.bookModal {
    background: #FAFAFA;
    border-top-left-radius: 15px;
    font-family: 'Muli', sans-serif;
    height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
    max-width: 700px;
    overflow-y: scroll;
    padding: 20px;
    position: fixed;
    top: 20px;
    left: 20px;
    transform: translate3d(calc(100vw - 20px), 0, 0);
    transition: transform .3s cubic-bezier(0, .52, 0, 1);
    width: calc(100% - 20px);
    z-index: 100;
}

.bookModalClass {
    overflow-y: initial;
    padding: 0;
    top: 0;
    max-height: 100vh;
    height: 100vh;
    border-top-left-radius: 0px;
    z-index: 99;
}

.bookModalOpen {
    transform: translate3d(0vw, 0, 0);
    box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.5); 
}

.classTab {
    border: 0;
    cursor: pointer;
    box-shadow: -2px 2px 4px  rgba(0,0,0,0.3); 
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    color: #ccc;
    background: #4a4a4a;
    left: -50px;
    top: 5px;
    padding: 20px 0;
    width: 50px;
    &:hover {
        background: darken(#4a4a4a, 10%);
    }
}

.classInner {
    overflow-y: scroll;
    max-height: 100%;
    padding: 20px;
}

.closeBook {
    cursor: pointer;
    position: fixed;
    right: 10px;
    top: 30px;
    z-index: 300;
}

.bookHeading {
    font-size: 24px;
}
.btnReread {
    color: $primary;
    position: relative;
    font-weight: bold;
    padding-left: 2.4rem!important;
    &:hover {
        color: $gray-3;
    }
    svg {
        position: absolute;
        left: 0;
        top: 1px;
    }
}

.flagBtn {
    cursor: pointer;
    color: $gray-1;
    &Active {
        color: lighten($primary, 5%);
        fill: lighten($primary, 5%);
    }
}
.flagSuccess {
    display: inline-flex;
    align-items: center;
    font-size: 1.4rem;
    margin: 0 0 0 1rem;
    font-weight: normal;
}

.bookAuthor {
    font-size: 18px;
    font-weight: 400;
}

.logList {
    margin-top: 2rem;
    padding-top: 2rem;
    margin-left: -2rem;
    margin-right: -2rem;
}
.logListFilters {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li {
        p {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        svg {
            width: 2rem;
            height: 2rem;
            margin-right: .5rem;
        }
    }
    li:not(:last-child) {
        margin-right: 2rem;

    }
}

.logListItem {
    border-bottom: 1px solid #E1E1E1;
    padding: 1.5rem 3.5rem 1.5rem 2rem;
    position: relative;
    &:nth-child(odd) {
        background: #fff;
    }
}

.logListItemCog {
    color: #BBB;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 15px;
    cursor: pointer;
}

.logListItem:hover .logListItemCog {
    color: darken(#BBB, 10%);
}

.logListItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &Main {
        display: flex;
        flex: 1;
        align-items: center;
    }
    &Date {
        flex-basis: 10rem;
        flex-shrink: 0;
    }
    &Pages {
        display: flex;
        align-items: center;
        svg {
            margin-right: .6rem;
        }
    }
    &Users {
        margin-left: 20px;
    }
}
.logListSection {
    margin-top: 1.2rem;
}


.recent {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 20px;
    margin: 20px 0;
    position: relative;
}

.recentLogHeader {
    display: flex;
    align-items: center;
    color: #929292;
    font-size: 1.2rem;
    margin-bottom: .5rem;
    padding: 0 1rem;
    &Main {
        min-width: 16rem;
    }
    &Week {
        margin-left: auto;
        text-align: center;
        min-width: 6rem;
    }
}
.recentLog {
    align-items: center;
    background: #FFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    font-family: 'Muli', sans-serif;
    padding: 10px;
    &Main {
        min-width: 12rem;
    }
}

.recentLogMiddle {
    border-radius: 0;
    margin: 5px 0;
}

.recentLogBottom {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.bandPill {
    border-radius: 1rem;
    height: .8rem;
    width: .8rem;
    margin-right: .5rem;
}
.recentData {
    margin-top: 5px;
    text-align: center;
}
.recentDateAgo {
    font-weight: bold;
    font-size: 1.6rem;
}
.recentDate,
.recentDateNo {
    display: block;
    font-size: 1.2rem;
    min-width: 6rem;
}
.recentDateNo {
    font-size: 1.6rem;
    font-weight: bold;
}
.ygLabel {
    background: #ABAD9A;
    color: #fff;
    padding: 3px 10px;
    border-radius: 3px;
    font-weight: normal;
    font-size: 1.2rem;
    margin-left: .5rem;
}
.linkedParents {
    position: relative;
    &Count {
        width: 18px;
        height: 18px;
        color: #fff;
        border-radius: 9px;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0097AF;
        position: absolute;
        bottom: -4px;
        left: -4px;
    }
}
.recentWordList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .5rem;
}
.recentWord {
    background: #3A3A3A;
    border-radius: 10px;
    color: #FFF;
    display: block;
    font-size: 14px;
    font-weight: 800;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 5px 10px;
    &Time {
        font-weight: normal;
        font-size: 10px;
    }
    input[type=checkbox] {
        visibility: visible;
        width: auto;
        height: auto;
        display: inline;
        margin-right: .5rem;
    }
}
.wordsCompleted {
    border-collapse: collapse;
    th {
        text-align: left;
        padding: 4px;
        border-bottom: 1px solid #ccc;
    }
    td {
        padding: 4px;
            border-bottom: 1px solid #ccc;
    }
}

.deleteHeader {
    font-size: 16px;
}

.deleteBookPanel{
    border-top: 1px solid #D8D8D8;
    margin-top: 40px;
    padding-top: 40px;   
}

.completeLabel {
    font-weight: bold;
    margin-left: 10px;
    padding-left: 16px;
    position: relative;
}
.completeIcon {
    left: 0;
    position: absolute;
    top: 1px;
}
.reviewBlock {
    align-items: center;
    display: flex;
    margin: 10px 0 20px 0;
}
.reviewImg {
    li {
        display: inline-block;
        margin-right: .5rem;
    }
    img {
        width: 3.6rem;
    }
}
.reviewBody {
    flex: 1;
    margin-left: 10px;
}
.reviewHeader {
    font-size: 16px;
    font-weight: bold;
}
@include breakpoint-up(sm) {
    .bookFilters {
        display: inline-block;
        transform: translate3d(-15px, 0, 0);
        width: initial;
    }
    .bookFiltersLog {
        display: flex;
        button {
            flex: auto 0;
        }
        &Action {
            margin-left: auto;
            margin-top: 1rem;
        }
    }
    .addBookBtn {
        display: block;
    }
    .bookModal {
        left: initial;
        right: 0;
        transform: translate3d(100%, 0, 0);
    }
    .bookModalOpen {
        transform: translate3d(0%, 0, 0);
    }
    .classTab {
        top: 10px;
    }
}


@include breakpoint-up(sm) {
    .noProbWords {
        color: #4a4a4a;
    }
}

@include breakpoint-up(lg) {
    .recent {
        grid-template-columns: auto auto auto auto;
        padding-top: 0;
    }
    .banding {
        align-items: center;
        flex-direction: column;
        position: initial;
        justify-content: center;
    }
    .bookList {
        width: 50%;
    }
}

.bookActionLog {
    padding-left: 24px !important;
    position: relative;
}

.bookActionLogIcon {
    position: absolute;
    left: -4px;
    top: -2px;
}

// student view styles

.studentContent {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header {
    align-items: center;
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
}

.studentName {
    color: #FFF;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
}

.banding {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 100%;
}

.bandLabel {
    background: #fff;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: bold;
    padding: 1px .5rem;
    color: #4a4a4a;
    display: inline-flex;
    align-items: center;
    margin-left: .5rem;
    top: -.5rem;
    position: relative;
    cursor: pointer;
    border: 1px solid lighten($gray-2, 1%);
}
.infoBtn {
    background: #FFF;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-left: auto;
    padding: 10px 10px 5px 10px;
    cursor: pointer;
    &:hover {
        background: #F1f1f1;
    }
}

.studentAva, .recentStudentAva {
    border-radius: 300px;
    height: 50px;
    margin: 0 10px 0 20px;
    width: 50px;
}

.studentBack {
    cursor: pointer;
}

.searchBar {
    align-items: center;
    background: #f1f1f1;
    //background: #C33764;
    display: flex;
    margin: 0 -20px 20px -20px;
    //padding: 0 20px;
}


.searchBarFilter {
    background: #3a3a3a;
    border: 0;
    color: #FFF;
    padding: 0.75em 1em;
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    &Active {
        background: #FFF;
        color: #000;
    }
    &:disabled {
        opacity: .5;
    }
}

.searchBtn {
    background: #FFF;
    border: none;
    padding: 4px 10px 2px 10px;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
}

@include breakpoint-up(sm) {
    .studentName {
        color: #4a4a4a;
    }
    .searchBar {
        background: #C33764;
        margin: 0 0 20px 0;
        width: 100%;
    }
    .infoBtn {
        margin-right: 20px;
    }
    .infoBtnClass {
        margin-right: 50px;
    }
}
.main {
    flex-direction: column;
    display: flex;
    flex: 1;
}

.books {
    /*padding: 20px;*/
    width: 100%;
    order: 2;
    /*width: calc(100% - 400px);*/
}



.aside {
    order: 1;
    background: #F6F6F6;
    padding: 20px;
    /*width: 400px;*/
    border-radius: 15px;
    margin-bottom: 20px;
}

@include breakpoint-up(sm) {
    .books {
        padding: 20px;
    }
    .aside {
        margin: 0 20px;
    }
}

@include breakpoint-up(lg) {
    .header {
        padding: 20px;
    }
    .searchBar {
        margin: 0;
    }
    .studentName {
        font-size: 24px;
    }
    .main {
        padding: 0;
        flex-direction: initial;
    }
    .books {
        padding: 20px;
        order: 1;
        width: calc(100% - 400px);
    }
    .aside {
        border-radius: 0;
        order: 2;
        margin: 0;
        width: 400px;
        min-height: 100%;
    }
}


.iconBtn {
    display: flex !important;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.4);
}
.infoHeader {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
}
.infoStudentAva {
    border-radius: 100px;
    height: 30px;
    margin-right: 5px;
    width: 30px;
}
.infoTitle {
    font-size: 22px;
}

.asideActions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
    :last-child {
        margin-left: .5rem;
    }
}
@include breakpoint-up(md) {
    .asideActions {
        :last-child {
            margin-left: 0;
        }
    }
}

.recentStudentAva {
    height: 30px;
    margin: 0 10px 0 0;
    width: 30px;
}

.recentLogImg {
    height: auto;
    margin-right: 10px;
    width: 30px;
}

.recentLogLabel {
    font-size: 12px;
    color: #929292;
    text-transform: capitalize;
}

.recentWeekly {
    margin-left: auto;
    text-align: center;
}
.studentLi {
    border-bottom: 1px solid #CCC;
    padding: 10px 0;
    display: flex;
    align-items: center;
}
.copied {
    font-size: 12px;
    box-shadow: 0 3px 5px rgba(0,0,0,0.5);
    color: #FFF;
    background: #000;
    border-radius: 10px;
    padding: 5px;
    position: absolute;
    top: -25px;
    left: 50%;
    opacity: 0;
    transition: opacity 0.2s linear;
}

.copiedShow {
    opacity: 1;
}

.editBookType {
    max-width: 28rem;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    label {
        font-weight: bold;
        margin-right: 1rem;
    }
    select{ 
        margin-bottom: 0;
    }
}

//switch 
.switchToggle{
	height: 0;
	width: 0;
	visibility: hidden;
}
.switchLabel {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 26px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.switchLabel:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 3px;
	width: 22px;
	height: 22px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}
.switchToggle:checked + label {
    background: #80b814!important;
}

.switchToggle:checked + label:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
}

.switchLabel:active:after {
	width: 40px;
}

.groupAlert {
    padding: 10px;
    display:flex;
    align-items: center;
    border-radius:10px;
    background-color: #ebebeb;
    margin-bottom: 20px;
    font-weight: bold;
    &Legacy {
        font-weight: normal;
    }
}

.addPupilList {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.25rem;

}


@import '../../styles/variables.scss';
@import '../../styles/_mixins.scss';

.classList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -.5rem;
    margin-right: -.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    &Item {
        display: flex;
        margin-bottom: 1rem;
        flex-basis: 50%;
        padding-left: .5rem;
        padding-right: .5rem;
        max-width: 50%;
        flex-shrink: 0;
    }
}
.classLink {
    border-radius: 1.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    padding: 4rem 1.5rem 1.5rem 1.5rem;
    background: #fff url('../../images/avatars.png') no-repeat center 2rem / 9.8rem 3rem;
    text-align: center;
    transition: all ease-in .1s;
    flex: 1;
    &Main  {
        padding-top: 2rem;
    }
    &Desc {
        font-size: 1.2rem;
        color: darken($gray-2, 15%);
    }
    &:hover {
        box-shadow: 0 3px 5px rgba(0,0,0,0.25);
    }
}

.title {
    font-weight: normal;
}


@include breakpoint-up(md) { 
    .classList {
        &Item {
            flex-basis: 33%;
            max-width: 33%;
        }
    }
}


@include breakpoint-up(lg) { 
    .classList {
        &Item {
            flex-basis: 25%;
            max-width: 25%;
        }
    }
}

.search {
    background: #f2f2f2;
    padding: 5px;
    border-radius: 15px;
    position: relative;
    display: flex;
    padding-right: 30px;
}
.searchBox {
    max-width: 400px;
}
.studentAva {
    border-radius: 100px;
    width: 25px;
    height: 25px;
    margin-right: 5px;
    flex: 0 0 auto; 
}
.inputHolder {
    position: relative;
    width: 100%;
    max-width: 400px;
}
.searchIcon {
    position: absolute;
    right: 7px;
    top: 8px;
}
.resultsWrapper {
    &List {
        position: absolute;
        top: 42px;
        left: 5px;
        max-width: 400px;
        right: 25px;
        background: #fff;
        max-height: 200px;
        overflow-y: auto;
        padding: 1.2rem 0;
        border-radius: 1rem;
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.2);
        border: 1px solid $gray-2;
        .searchLink {
            padding: .5rem 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: bold;
            .studentName {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 0;
            }
            .className {
                font-weight: normal;
                margin-left: auto;
                font-size: 12px;
                flex: 0 0 auto; 
            }
            &:hover {
                background: #f2f2f2;
            }
        }
        .searchLinkNotFound {
            padding: .5rem 1rem;
            display: flex;
            align-items: center;
        }
    }
}
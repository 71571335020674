.gemButton {
    align-items: center;
    display: flex!important;
    justify-content: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 1rem;
        padding: 5px 12px;
    &Img {
        margin-right: .5rem;
    }
    &Confirmed {
        height: 2.1rem;
        width: 2.1rem;
    }
}
.stHeader {
    a {
        font-weight: normal;
        font-size: 1.6rem;
        text-decoration: underline;
        margin-left: 1rem;
    }
}
.countItem {
    display: flex;
    align-items: center;
    margin: .5rem 0;
    span {
        margin-right: 1rem;
        flex-basis: 5rem;
        max-width: 5rem;
        padding: .3rem .5rem;
        background: #D9D9D9;
        border-radius: 1rem;
        text-align: center;
        font-weight: bold;
    }
}
.wrapper {
    display: flex;
    align-items: flex-start;
    &Main {
        flex-basis: 35rem;
        max-width: 35rem;
        flex-shrink: 0;
        margin-right: 2rem;
    }
    &Side {
        flex: 1;
    }
}
.wrapperPanel {
    padding: 1.5rem;
    border: 1px solid #ccc;
    margin-bottom: 2rem;
    border-radius: 1rem;
}
.bookStats {
    margin: 2rem 0 3rem 0;
    li {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
    }
    &Label {
        flex-basis: 12rem;
        max-width: 12rem;
        flex-shrink: 0;

    }
    &BarHolder {
        background: #F4F4F4;
        flex-basis: 15rem;
        max-width: 15rem;
        height: 1.8rem;
    }
    &BarValue {
        background: #C43464;
        height: 1.8rem;

    }
    &BarNo {
        flex-basis: 3.5rem;
        max-width: 3.5rem;
        flex-shrink: 0;
        text-align: right;
        font-weight: bold;
        padding: 0 .5rem;
        background: #D9D9D9;
        border-radius: .5rem;
        margin-left: .5rem;
        font-size:  1.4rem;
    }
}

.bookHistory {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: space-between;
    &Selector select {
        padding-right: 3rem;
    }
}

.bands {
    li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;
        font-size: 1.4rem;
    }
    &Content {
        flex: 1;
    }
    &Title {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        p {
            max-width: 15rem;
            overflow: hidden;
        }
    }
    &Pill {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 3rem;
        margin: 4px 10px 0 0;
    }
}
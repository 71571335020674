@import '../../styles/variables.scss';
@import '../../styles/_mixins.scss';

.classFlex {
    margin-top: 20px;
}
.classPrintLetters {
    margin: 1rem 0 0 0;
    display: block;
}

@include breakpoint-up(md) {
    .classPrintLetters {
        margin: 0 0 0 1rem;
        display: inline-block;
    }
}

@include breakpoint-up(lg) { 
    .classFlex {
        display: flex;
    }
    .classStudents {
        flex: 1;
        max-width: 700px;
    }

}
.studentViewHeading {
    color: #000;
}

.btn-reset {
    border: 0;
    padding: 0;
    cursor: pointer;
    text-align: left;
    &:focus {
        outline: none;
    }
}
button {
    cursor: pointer;
}

.btn-danger {
    background: #A03213!important;
    border-color: #A03213!important;
    &:hover {
        background: lighten(#A03213, 10%)!important;
    }
}
.btn-primary {
    background: $mid-green;
    border-color:$mid-green;
    text-transform: uppercase;
    color: #fff;
    border-radius: 1rem;
        font-weight: bold;
    &:hover {
        background: $primary;
    }
}
.btn-purple {
    background: $accent-2;
    border-color: $accent-2;
    text-transform: uppercase;
    color: #fff;
    border-radius: 1rem;
    font-weight: bold;
    &:hover {
        background: lighten($accent-2, 5%);
    }
}

.btn-blue {
    background: $tertiary;
    border-color: $tertiary;
    text-transform: uppercase;
    color: #fff;
    border-radius: 1rem;
    font-weight: bold;
    &:hover {
        background: lighten($tertiary, 5%);
    }
}
.btn-tomato {
    background: $mid-green;
    border-color: $mid-green;
    text-transform: uppercase;
    color: #fff;
    border-radius: 1rem;

    &:hover {
        background: $primary;
    }
}
.btn-small {
    padding: 5px 12px;
}
.btn-outline,
.btn-link {
    border: 1px solid lighten($primary, 10%);
    text-transform: uppercase;
    color: lighten($primary, 10%);
    padding: 5px 15px;
    border-radius: 1rem;
    font-weight: bold;
}
.btn-link {
    color: #fff;
    background: lighten($primary, 10%);
}